// PrimeReact
 @import 'primereact/resources/themes/saga-purple/theme.css';
// @import "primereact/resources/themes/bootstrap4-light-purple/theme.css";
// @import 'primereact/resources/themes/mdc-dark-deeppurple/theme.css';
@import "primereact/resources/primereact.min.css";
@import "primeicons/primeicons.css";
// PrimeFlex
@import "primeflex/src/_variables.scss";
@import "primeflex/src/_grid.scss";
@import "primeflex/src/_formlayout.scss";
@import "primeflex/src/_display.scss";
@import "primeflex/src/_text.scss";
@import "primeflex/src/flexbox/_flexbox.scss";
@import "primeflex/src/_spacing.scss";
@import "primeflex/src/_elevation.scss";

html,
body,
#root {
  height: 100%;
}

.App {
  min-height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
  background-color: var(--surface-300);
}

.AppFooter {
  text-align: center;
  padding-top: 6em;
  padding-bottom: 4em;
}

.p-ripple {
  &.orange .p-ink {
    background-color: orangered;
  }
  &.green .p-ink {
    background-color: green;
  }
  &.white .p-ink {
    background-color: silver;
  }
}

// dataview customizations
.p-dataview {
  &.p-dataview-list {
    .p-dataview-content {
      padding: 0;
      .dataview-list-item {
        padding: 16px 1rem 8px 1rem;
        height: fit-content;
        &:hover {
          background-color: var(--surface-200);
        }
      }
    }
  }
  &.p-dataview-grid {
    // consistent gap 
    .p-dataview-content {
      padding-top: 16px;
      padding-left: 16px;
      .dataview-grid-card-container {
        padding-right: 16px;
        padding-bottom: 16px;
        height: fit-content;
        .dataview-grid-card {
          background-color: var(--surface-100);
          &:hover {
            background-color: var(--surface-200);
          }
        }
      }
    }
  }
  .p-dataview-emptymessage {
    padding: 1rem;
  }
}

.primary-bg {
  background-color: var(--primary-color);
  color: var(--primary-color-text);
}

a {
  text-decoration: unset;
  color: unset;
}