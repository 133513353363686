
nav.admin-menu {
    ul {
        list-style: none;
        font-size: 4rem;
        li {
            margin-bottom: 1.5rem;
            & > a {
                transition: color ease 0.3s;
                color: var(--text-color);
                text-decoration: none;
                &:hover {
                    color: var(--primary-color);
                    box-shadow: black;
                    cursor: pointer;
                }
            }
        }
    }
}

@media (max-width: 600px) {
    nav.admin-menu {
        ul {
            font-size: 2.5rem;
        }
    }
}